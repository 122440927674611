import React from "react"
import InnerHeader from "../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import * as styles from "./joinOurFamily.module.scss"

const JoinOurFamily = ({ pageContext, location }) => {
  const pageTitle = "Become a Dealer"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <div className={styles.contentWrapper}>
          <h2>Become a Dealer</h2>
          <h3 className="h4">
            We are excited that you are interested in joining the Rocky Brands
            family of authorized dealers. To ensure your request is properly
            routed, please complete the below questionnaire.
          </h3>
          <div>
            <form
              data-netlify="true"
              name="join-our-family-of-authorized-dealers"
              method="POST"
              data-netlify-honeypot="bot-field"
            >
              <input
                type="hidden"
                name="form-name"
                value="join-our-family-of-authorized-dealers"
              ></input>
              {/* checkboxes */}
              <div className={styles.checkboxGroup}>
                {/* brands */}
                <fieldset className={styles.brands}>
                  <legend htmlFor="brands">
                    <strong>What Brand(s) are you interested in?</strong>
                  </legend>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        id="become-a-dealer-rocky-boots"
                        name="brands"
                        value="Rocky Boots"
                      />
                      <span>Rocky Boots</span>
                    </label>
                  </div>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        id="become-a-dealer-georgia-boot"
                        name="brands"
                        value="Georgia Boot"
                      />
                      <span>Georgia Boot</span>
                    </label>
                  </div>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        id="become-a-dealer-durango"
                        name="brands"
                        value="Durango"
                      />
                      <span>Durango</span>
                    </label>
                  </div>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        name="brands"
                        id="become-a-dealer-muck"
                        value="Muck Boot Co"
                      />
                      <span>The Original Muck Boot Company</span>
                    </label>
                  </div>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        id="become-a-dealer-xtratuf"
                        name="brands"
                        value="XTRATUF"
                      />
                      <span>XTRATUF</span>
                    </label>
                  </div>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        id="become-a-dealer-ranger"
                        name="brands"
                        value="Ranger"
                      />
                      <span>Ranger</span>
                    </label>
                  </div>
                </fieldset>
                {/* division */}
                <fieldset className={styles.division}>
                  <legend htmlFor="division">
                    <strong>
                      What Division of Rocky are you interested in?
                    </strong>
                  </legend>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        id="become-a-dealer-outdoor-hunting"
                        name="brands"
                        value="Outdoor/Hunting"
                      />
                      <span>Outdoor / Hunting</span>
                    </label>
                  </div>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        id="become-a-dealer-work"
                        name="brands"
                        value="Work"
                      />
                      <span>Work</span>
                    </label>
                  </div>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        id="become-a-dealer-western"
                        name="brands"
                        value="Western"
                      />
                      <span>Western</span>
                    </label>
                  </div>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        name="brands"
                        id="become-a-dealer-duty-military"
                        value="Duty / Military"
                      />
                      <span>Duty / Military</span>
                    </label>
                  </div>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        id="become-a-dealer-marine"
                        name="brands"
                        value="Marine"
                      />
                      <span>Marine</span>
                    </label>
                  </div>
                  <div>
                    <label className="checkbox-alt">
                      <input
                        type="checkbox"
                        id="become-a-dealer-apparel"
                        name="brands"
                        value="Apparel"
                      />
                      <span>Apparel</span>
                    </label>
                  </div>
                </fieldset>
              </div>
              {/* end checkboxes */}
              {/* company name, contact name, email, address, address line 2, city, country */}
              <div classname={styles.companyToCountry}>
                <div>
                  <label htmlFor="company-name">Company Name</label>
                  <input type="text" id="company-name" name="company-name" />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "2rem",
                  }}
                >
                  <div style={{ width: `100%` }}>
                    <label htmlFor="contact-first-name">First Name</label>
                    <input
                      type="text"
                      id="contact-name-first"
                      name="contact-first-name"
                    />
                  </div>
                  <div style={{ width: `100%` }}>
                    <label htmlFor="contact-last-name">Last Name</label>
                    <input
                      type="text"
                      id="contact-name-last"
                      name="contact-last-name"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" name="email" />
                </div>
                <div>
                  <label htmlFor="address">Address</label>
                  <input type="text" id="address" name="address" />
                </div>
                <div>
                  <label htmlFor="address-2">Address 2</label>
                  <input type="text" id="address-2" name="address-2" />
                </div>
                <div>
                  <label htmlFor="city">City</label>
                  <input type="text" id="city" name="city" />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "2rem",
                  }}
                >
                  <select name="state" id="state" required>
                    <option value="" selected="selected">
                      State
                    </option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                  <select id="country" name="country">
                    <option>Country</option>
                    <option value="AF">Afghanistan</option>
                    <option value="AX">Aland Islands</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AS">American Samoa</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AQ">Antarctica</option>
                    <option value="AG">Antigua and Barbuda</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermuda</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">Bolivia</option>
                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                    <option value="BA">Bosnia and Herzegovina</option>
                    <option value="BW">Botswana</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BR">Brazil</option>
                    <option value="IO">British Indian Ocean Territory</option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>
                    <option value="CA">Canada</option>
                    <option value="CV">Cape Verde</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="CF">Central African Republic</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CC">Cocos (Keeling) Islands</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo</option>
                    <option value="CD">
                      Congo, Democratic Republic of the Congo
                    </option>
                    <option value="CK">Cook Islands</option>
                    <option value="CR">Costa Rica</option>
                    <option value="CI">Cote D'Ivoire</option>
                    <option value="HR">Croatia</option>
                    <option value="CU">Cuba</option>
                    <option value="CW">Curacao</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GF">French Guiana</option>
                    <option value="PF">French Polynesia</option>
                    <option value="TF">French Southern Territories</option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GR">Greece</option>
                    <option value="GL">Greenland</option>
                    <option value="GD">Grenada</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GG">Guernsey</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="HM">
                      Heard Island and Mcdonald Islands
                    </option>
                    <option value="VA">Holy See (Vatican City State)</option>
                    <option value="HN">Honduras</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IR">Iran, Islamic Republic of</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IM">Isle of Man</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JE">Jersey</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KP">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="KR">Korea, Republic of</option>
                    <option value="XK">Kosovo</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LA">Lao People's Democratic Republic</option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libyan Arab Jamahiriya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MO">Macao</option>
                    <option value="MK">
                      Macedonia, the Former Yugoslav Republic of
                    </option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="YT">Mayotte</option>
                    <option value="MX">Mexico</option>
                    <option value="FM">Micronesia, Federated States of</option>
                    <option value="MD">Moldova, Republic of</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="ME">Montenegro</option>
                    <option value="MS">Montserrat</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="AN">Netherlands Antilles</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PS">Palestinian Territory, Occupied</option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Reunion</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda</option>
                    <option value="BL">Saint Barthelemy</option>
                    <option value="SH">Saint Helena</option>
                    <option value="KN">Saint Kitts and Nevis</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="MF">Saint Martin</option>
                    <option value="PM">Saint Pierre and Miquelon</option>
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="ST">Sao Tome and Principe</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="RS">Serbia</option>
                    <option value="CS">Serbia and Montenegro</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SX">Sint Maarten</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="GS">
                      South Georgia and the South Sandwich Islands
                    </option>
                    <option value="SS">South Sudan</option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SJ">Svalbard and Jan Mayen</option>
                    <option value="SZ">Swaziland</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syrian Arab Republic</option>
                    <option value="TW">Taiwan, Province of China</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">Tanzania, United Republic of</option>
                    <option value="TH">Thailand</option>
                    <option value="TL">Timor-Leste</option>
                    <option value="TG">Togo</option>
                    <option value="TK">Tokelau</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad and Tobago</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TC">Turks and Caicos Islands</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>
                    <option value="US">United States</option>
                    <option value="UM">
                      United States Minor Outlying Islands
                    </option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VE">Venezuela</option>
                    <option value="VN">Viet Nam</option>
                    <option value="VG">Virgin Islands, British</option>
                    <option value="VI">Virgin Islands, U.s.</option>
                    <option value="WF">Wallis and Futuna</option>
                    <option value="EH">Western Sahara</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>
              </div>
              {/* end company name, contact name, email, address, address line 2, city, country */}
              {/* REGIONS */}
              <fieldset className={styles.regions}>
                <legend htmlFor="become-a-dealer-region">
                  <strong>What Regions are you interested in?</strong>
                </legend>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="become-a-dealer-region-northeast"
                      name="become-a-dealer-region"
                      value="Northeast"
                    />
                    <span>Northeast</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="become-a-dealer-region-mid-atlantic"
                      name="become-a-dealer-region"
                      value="Mid Atlantic"
                    />
                    <span>Mid Atlantic</span>
                  </label>
                </div>
                <div>
                  {" "}
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="become-a-dealer-region-south"
                      name="become-a-dealer-region"
                      value="South"
                    />
                    <span>South</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="become-a-dealer-region-midwest"
                      name="become-a-dealer-region"
                      value="Midwest"
                    />
                    <span>Midwest</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="become-a-dealer-region-southwest"
                      name="become-a-dealer-region"
                      value="Southwest"
                    />
                    <span>Southwest</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="become-a-dealer-region-northwest"
                      name="become-a-dealer-region"
                      value="northwest"
                    />
                    <span>Northwest</span>
                  </label>
                </div>
                <div>
                  {" "}
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="become-a-dealer-region-canada"
                      name="become-a-dealer-region"
                      value="Canada"
                    />
                    <span>Canada</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="become-a-dealer-region-other"
                      name="become-a-dealer-region"
                      value="international"
                    />
                    <span>Rest of the World</span>
                  </label>
                </div>
              </fieldset>
              {/* end regions */}
              {/* radio button 'Are you interested in our drop shipping program?' */}
              <fieldset className={styles.dropShipping}>
                <legend htmlFor="drop-shipping">
                  <strong>
                    Are you interested in our drop shipping program?
                  </strong>
                </legend>
                <div>
                  <label className="radio-alt">
                    <input
                      type="radio"
                      name="drop-shipping"
                      id="drop-shipping-yes"
                      value="Yes"
                    />
                    <span>Yes</span>
                  </label>
                </div>
                <div>
                  <label className="radio-alt">
                    <input
                      type="radio"
                      name="drop-shipping"
                      id="drop-shipping-no"
                      value="No"
                    />
                    <span>No</span>
                  </label>
                </div>
              </fieldset>
              {/* end radio button 'Are you interested in our drop shipping program?' */}
              {/* radio button 'Do you support standard EDI protocol?' */}
              <fieldset className={styles.edi}>
                <legend htmlFor="edi">
                  <strong>Do you support standard EDI protocol?</strong>
                </legend>
                <div>
                  <label className="radio-alt">
                    <input type="radio" name="edi" id="edi-yes" value="Yes" />
                    <span>Yes</span>
                  </label>
                </div>
                <div>
                  <label className="radio-alt">
                    <input type="radio" name="edi" id="edi-no" value="No" />
                    <span>No</span>
                  </label>
                </div>
              </fieldset>
              {/* end radio button 'Do you support standard EDI protocol?' */}
              <div>
                <input className="CTAblack" type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JoinOurFamily
